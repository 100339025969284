import { Context } from 'index'
import { useContext, useMemo } from 'react'
import { PersonalInfo as PIInterface } from './PersonalInfo.types'

export const profileInfoSections: PIInterface = {
  phones: {
    order: 1,
    name: 'Телефоны',
    iconName: 'LocalPhoneOutlinedIcon',
  },
  persons: {
    order: 2,
    name: 'Персоны',
    iconName: 'PersonSearchOutlinedIcon',
  },
  birthdays: {
    order: 3,
    name: 'Возможные даты рождения',
    iconName: 'CakeOutlinedIcon',
  },
  addresses: {
    order: 4,
    name: 'Возможные адреса',
    iconName: 'NearMeOutlinedIcon',
  },
  urls: {
    order: 5,
    name: 'Ссылки',
    iconName: 'LanguageIcon',
  },
  emails: {
    order: 6,
    name: 'Электронные почты',
    iconName: 'AlternateEmailIcon',
  },
}

export const vkKeys = {
  busyness: 'работа',
  created: 'дата регистрации',
  birthday: 'день рождения',
  fio_: 'фио',
  gender: 'пол',
  name: 'имя',
  online: 'был(а) в сети',
  place: 'город',
  photos: 'фото',
  sites: 'сайты',
  login: 'логин',
} as { [k: string]: string }

export const keysNames = {
  segment_age: 'Возраст',
  segment_gender: 'Пол',
  segment_area: 'Округ',
  segment_district: 'Район',
  segment_age_of_children: 'Возраст детей',
  studenti: 'Студенты',
  pensioneri: 'Пенсионеры',
  roditeli: 'Родители',
  mnogodetnie_semii: 'Многодетные семьи',
  invalidi: 'Инвалиды',
  posetiteli_poliklinik: 'Посетители поликлиник',
  predpencioneri_ot_55_let: 'Предпенсионеры от 55 лет',
  mnogodetnie_materi: 'Многодетные матери',
  molodezh_18_35: 'Молодеж от 18 до 35 лет',
  lgotniki_po_bolezni: 'Льготники по болезни',
  aktivniy_polzovatel_mosru: 'Активные пользователи "mos.ru"',
  period_from: 'Дата от',
  period_to: 'Дата до',
  phone: 'Телефон',
} as { [k: string]: any }

export const usePersonalInfo = () => {
  const { rootStore } = useContext(Context)
  const { profileStore } = rootStore

  const {
    apps,
    metaData,
    socialProfileData: rowSocialProfileData,
  } = profileStore

  const profileDataByView = useMemo(
    () =>
      metaData
        ? Object.fromEntries(
            Object.entries(metaData).filter(([key, values]) => {
              return (
                key !== 'grzs' &&
                key !== 'marks' &&
                Array.isArray(values) &&
                values.length
              )
            })
          )
        : null,
    [metaData]
  )

  const combinedApps = useMemo(() => {
    const result: any = []

    apps?.forEach((app: any, idx: any) => {
      if (idx === 0) {
        return result.push(app)
      }
      const sameNameAppInIdx = result.findIndex(
        (resApp: any) => resApp?.application === app?.application
      )

      if (sameNameAppInIdx > -1) {
        if (
          new Date(result?.[sameNameAppInIdx]?.period_to) <
          new Date(app?.period_to)
        ) {
          //приложение с датой последнего входа раньше, чем у итерируемого фильтруем и заменяем новым
          result.filter(
            (el: any, filterIdx: any) => filterIdx !== sameNameAppInIdx
          )
          return result.push(app?.period_to)
        }

        //если дата больше - просто выходим
        return
      }

      return result.push(app)
    })

    return result
  }, [apps])

  const normalizedProfileData = useMemo(
    () =>
      profileDataByView
        ? Object.entries(profileDataByView)
            ?.filter(([_, val]: any) => val && val.length)
            ?.sort(([key1, _val1]: any, [key2, _val2]: any) =>
              profileInfoSections?.[key1]?.order <
              profileInfoSections?.[key2]?.order
                ? -1
                : 0
            )
        : null,
    [profileDataByView]
  )

  const socialProfileData = useMemo(() => {
    if (rowSocialProfileData?.length) {
      return Object?.entries(rowSocialProfileData?.[0] as any)
        ?.filter(([key, val]: any) => {
          if (key === 'segment_age_of_children' && !!val && !val.length) {
            return false
          }

          return (
            val !== '0' &&
            val !== 0 &&
            val !== '' &&
            val !== null &&
            val !== undefined &&
            key !== 'period_from' &&
            key !== 'period_to'
          )
        })
        ?.map(([key, val]: any) => {
          if (key === 'segment_age_of_children' && !!val) {
            return [
              key,
              val?.map((v: string, idx: number, arr: string[]) =>
                idx !== arr?.length - 1 ? `${v}, ` : v
              ),
            ]
          }

          if (val === '1') {
            return [key, 'Да']
          }

          return [key, val]
        })
        ?.sort(([key, _]) => (key === 'phone' ? -1 : 0))
    }

    return null
  }, [rowSocialProfileData])

  return {
    combinedApps,
    profileDataByView,
    normalizedProfileData,
    socialProfileData,
  }
}

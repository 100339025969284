import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useState } from 'react'
import {
  PhoneRelationLink,
  PhoneRelationLinkSource,
  Relationships,
} from 'store/profileStore/profileStore.types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { Box, Stack, Typography } from '@mui/material'
import { SubheaderIcon } from 'components/Profile/components/SubheaderIcon'
import { v4 } from 'uuid'
import TR from 'assets/translations/translations.json'

const tranlsations: Record<string, string> = {
  _index: 'Источник',
  _index_source: 'Источник связи',
  _mark: 'Маркер',
  _link_source: 'Связанный источник',
  _description: 'Описание утечки',
  _connect: 'Связанные поля',
  _value: 'Номер документа',
}

interface Params {
  data: Relationships['phones']
}

const PhoneLinkSource = observer(
  ({
    sourceName,
    source,
  }: {
    sourceName?: string
    source: PhoneRelationLinkSource
  }) => {
    if (!source || !Object.keys(source).length) {
      return null
    }

    const getKey = (key: string) => {
      if (sourceName && (TR as any)?.[sourceName][key]) {
        return (TR as any)?.[sourceName][key]
      }

      if (key === 'time_search') {
        return 'Время поиска'
      }

      if (key) {
        return key
      }
    }

    return (
      <>
        <Accordion key={v4()} sx={{ width: '100%' }}>
          <AccordionSummary>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='LocalPhoneOutlinedIcon' />
            </Box>
            <Typography variant='body1' color='common.black' textAlign='start'>
              Источник
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(source).map(([key, val]) => (
              <Stack sx={{ width: '100%' }} key={v4()}>
                <Stack
                  sx={{ width: '100%', p: 1 }}
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography variant='body1' color='common.black'>
                    {getKey(key)}
                  </Typography>
                  <Typography
                    variant='body1'
                    width='50%'
                    textAlign='end'
                    color='text.disabled'
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {key === 'time_search'
                      ? new Date(val).toLocaleString()
                      : val}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
)

const getValue = (
  key: string,
  value: string,
  index?: string,
  index_source?: string
) => {
  if (key === '_connect') {
    const _keys = value.split(' ').filter((i) => i.startsWith('doc_'))

    const [k1, k2] = _keys
    if (index && index_source) {
      const translation1 = (TR as any)?.[index]?.[k2]
      const translation2 = (TR as any)?.[index_source]?.[k1]

      if (translation1 && translation2) {
        return `${translation1} → ${translation2}`
      }
    }

    return _keys.join(' → ')
  }

  return value
}

const PhoneLinks = observer(({ links }: { links?: PhoneRelationLink[] }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!links?.length) {
    return null
  }

  return (
    <>
      {links.map((link, idx) => (
        <Accordion
          key={v4()}
          sx={{ width: '100%' }}
          expanded={expanded === `link-${idx}`}
          onChange={handleChange(`link-${idx}`)}
        >
          <AccordionSummary>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='LocalPhoneOutlinedIcon' />
            </Box>
            <Typography variant='body1' color='common.black' textAlign='start'>
              {getValue(
                '_connect',
                link._connect || '',
                link._index,
                link._index_source
              ) || `Связь #${idx + 1}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(link).map(([key, val]) => (
              <React.Fragment key={v4()}>
                {key === '_source' && val && Object.keys(val).length ? (
                  <PhoneLinkSource sourceName={link._index} source={val} />
                ) : null}

                {key !== '_source' &&
                key !== '_index' &&
                key !== '_description' &&
                key !== '_index_source' ? (
                  <Stack sx={{ width: '100%' }} key={v4()}>
                    <Stack
                      sx={{ width: '100%', p: 1 }}
                      direction='row'
                      justifyContent='space-between'
                    >
                      <Typography variant='body1' color='common.black'>
                        {tranlsations?.[key] ?? key}
                      </Typography>
                      <Typography
                        variant='body1'
                        width='50%'
                        textAlign='end'
                        color='text.disabled'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {getValue(key, val, link._index, link._index_source)}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : null}
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
})

export const PhoneRels = observer<Params>(({ data }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  if (!data?.length) {
    return null
  }

  return (
    <Stack mt={1} width='100%'>
      {data.map(({ src_name, value, links }, idx) => (
        <Accordion
          key={v4()}
          sx={{ width: '100%' }}
          expanded={expanded === `phone-${idx}`}
          onChange={handleChange(`phone-${idx}`)}
        >
          <AccordionSummary>
            <Box
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubheaderIcon name='LocalPhoneOutlinedIcon' />
            </Box>
            <Typography variant='body1' color='common.black' textAlign='start'>
              {`${src_name ? `${src_name} - ` : ''}${value}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PhoneLinks links={links} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )
})

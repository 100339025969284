/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Context } from 'index'
import { useCallback, useContext, useMemo } from 'react'
import autoTable, { RowInput } from 'jspdf-autotable'
import { TextOptionsLight } from 'jspdf'
import { GeoInfo, TgChange } from 'store/profileStore/profileStore.types'
import {
  translateAppKey,
  translateRelKey,
} from './PersonalInfo/PersonalInfo/PersonalInfo.utils'
import JsPDF from 'jspdf'
import { useFinesData } from './ProfileTransportInfo/useFinesData'
import { useGibddInfo } from './GibddInfo/useGibddInfo'
import { useInsuranceData } from './InsuranceData/useInsuranceData'
import { useEffect, useState } from 'react'
import {
  vkKeys,
  keysNames,
  usePersonalInfo,
  profileInfoSections,
} from './PersonalInfo/PersonalInfo/usePersonalInfo'
import 'svg2pdf.js'
import html2canvas from 'html2canvas'

interface TextParams {
  text: string | string[]
  x: number
  y: number
  options?: TextOptionsLight
  transform?: number | any
}

const translatdeTgChangeKeys: TgChange = {
  name: 'Имя',
  date: 'Дата',
  user_name: 'Имя Пользователя',
}

const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${DD}.${MM}.${YYYY} - ${hour}:${min}`
}

const transportFields = {
  grzs: {
    order: 3,
    name: 'Автомобильные номера',
  },
  marks: {
    order: 7,
    name: 'Автомобильные бренды',
  },
} as any

const mapLayers = {
  locations: 'Локации',
  routes: 'Маршруты',
  districts: 'Районы',
  lastRoute: 'Последний маршрут',
  userDetections: 'Детекции Tg',
  notSubscribedHexagons: 'Сообщества Tg',
  vkGeo: 'Vk Geo',
  yandexFood: 'YandexFood',
  deliveryClub: 'Delivery Club',
  velobikes: 'Велосипеды',
} as any

const splitArrayToRowInputArr = (arr: string[], number: number) => {
  const result = []
  for (let i = 0; i < arr.length; i += number) {
    result.push(arr.slice(i, i + number))
  }
  return result
}

const syncLoadAllImages = (
  imageQueue: string[],
  photoSize: number,
  callback: any
) => {
  const numAll = imageQueue.length
  let numProcessed = 0
  const allImages: any[] = []

  imageQueue.forEach((img: string) => {
    if (img.startsWith('/9j/')) {
      allImages.push(img)
      return
    }
    const image = new Image()
    image.crossOrigin = 'anonymous'

    image.addEventListener(
      'load',
      () => {
        numProcessed++
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = photoSize
        canvas.height = photoSize

        if (ctx) {
          ctx.drawImage(image, 0, 0, photoSize, photoSize)

          const base64 = canvas.toDataURL('image/jpeg')

          allImages.push(base64)

          if (numAll === numProcessed) {
            callback(allImages)
          }
        }
      },
      false
    )

    image.addEventListener('error', () => {
      numProcessed++
    })

    image.src = img
  })
}

export const useProfileReport = ({ tableRef, transportInfo, type }: any) => {
  const { rootStore } = useContext(Context)
  const { profileStore, mapStore } = rootStore

  const {
    additionalImagesAdded,
    tgData,
    links,
    additionalInfo,
    subscribedChannels,
    notSubscribedChannels,
    vk,
    relations,
    userPhotos,
    additionalImages,
    tripPhotosTableData,
    accidents,
    transportProfile,
    avtocodData,
    autoPairsData,
    setIsSaving,
    setScreeningLayer,
  } = profileStore

  const { tgId, tgUserName, vkId } = links
  const { registration, communities, changes, servers, geoInfo } = tgData

  const { normalizedProfileData, socialProfileData, combinedApps } =
    usePersonalInfo()

  const socProfData = useMemo(
    () => socialProfileData?.map(([key, val]: any) => [keysNames[key], val]),
    [socialProfileData]
  )

  const autoPhotos = useMemo(() => {
    const result: string[] = []

    if (additionalImagesAdded && additionalImages?.length) {
      additionalImages.forEach((photo) => result.push(photo))
    }

    return result
  }, [additionalImagesAdded, additionalImages])

  const { fines, finesData, getFineDay } = useFinesData()

  const { gibddData, translateGibdd } = useGibddInfo()

  const { insurance, translateInsurance } = useInsuranceData()

  const { possibleSelectors } = mapStore

  const grzData =
    transportProfile?.parking_connections?.search_type === 'grz'
      ? transportProfile.parking_connections
      : null
  const phoneData =
    transportProfile?.parking_connections?.search_type === 'phone'
      ? transportProfile.parking_connections
      : null

  const summary = transportProfile?.summary

  const hasSummary = useMemo(
    () =>
      summary &&
      Object.values(summary)?.length &&
      Object.values(summary).some((val) => {
        if (typeof val !== 'object') {
          return !!val
        }

        if (Array.isArray(val)) {
          return val.length
        }

        return val && Object.values(val)?.length
      }),
    [summary]
  )

  const [btns, setBtns] = useState<null | HTMLElement[]>(null)
  const [initialActiveBtns, setInitialActiveBtns] = useState<string[]>([])
  const [doc, setDoc] = useState<JsPDF>()
  const [isReadyToAddNewScreen, setIsReadyToAddNewScreen] = useState(true)

  const [userPhotosBase64, setUserPhotosBase64] = useState<string[]>([])
  const [autoPhotosBase64, setAutoPhotosBase64] = useState<string[]>([])

  useEffect(() => {
    if (userPhotos?.length && !userPhotosBase64.length) {
      const updatedPhotos = userPhotos.map((img) =>
        img
          .replace('https://isedc.ru', 'https://ic-dev.ru')
          .replace('http://pakpm.mos.ru', 'https://ic-dev.ru')
      )
      syncLoadAllImages(updatedPhotos, 175, (data: string[]) =>
        setUserPhotosBase64(data)
      )
    }

    if (autoPhotos?.length && !autoPhotosBase64.length) {
      const updatedPhotos = autoPhotos.map((img) =>
        img
          .replace('https://isedc.ru', 'https://ic-dev.ru')
          .replace('http://pakpm.mos.ru', 'https://ic-dev.ru')
      )

      syncLoadAllImages(updatedPhotos, 175, (data: string[]) =>
        setAutoPhotosBase64(data)
      )
    }
  }, [autoPhotos, autoPhotosBase64.length, userPhotos, userPhotosBase64.length])

  const handleDownloadPdf = useCallback(() => {
    const doc = new JsPDF('p', 'pt', 'a4', true)

    const subheaderML = 20

    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

    let currentY = 40

    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular-normal', 'normal')
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto-Bold-normal', 'normal')

    doc.setFont('Roboto-Regular-normal')

    const addHeader = (
      text: string,
      ML: number = pageWidth / 2,
      MT: number = currentY
    ) => {
      doc.setFontSize(20)

      if (currentY >= pageHeight || pageHeight - currentY <= 20) {
        doc.addPage()
        currentY = 20
        doc.text(text, ML, currentY, { align: 'center' })
      } else {
        doc.text(text, ML, MT, { align: 'center' })
      }

      currentY += 20
    }

    const addSubheader = (
      text: string,
      ML: number = subheaderML,
      MT: number = currentY,
      small?: boolean
    ) => {
      doc.setFontSize(small ? 12 : 16)

      if (currentY >= pageHeight || pageHeight - currentY <= 20) {
        doc.addPage()
        currentY = 20
        doc.text(text, ML, currentY)
      } else {
        doc.text(text, ML, MT)
      }

      currentY += 20
    }

    const addPlainText = ({ ...props }: TextParams) => {
      const { text, x, y, options } = props

      doc.setFontSize(12)

      if (currentY >= pageHeight || pageHeight - currentY <= 20) {
        doc.addPage()
        currentY = 20
        doc.text(text, x, currentY, options)
      } else {
        doc.text(text, x, y, options)
      }

      currentY += 20
    }

    const renderProfileData = (from: number, to: number) => {
      if (
        normalizedProfileData &&
        normalizedProfileData?.slice(from, to)?.length
      ) {
        let head: RowInput[] = []
        let body: RowInput[] = []

        normalizedProfileData
          .slice(from, to)
          .forEach(([type, vals]: any, idx) => {
            const initialPageNumber = doc.getCurrentPageInfo().pageNumber

            if (vals.length) {
              const tableHead = [
                profileInfoSections?.[type]?.name,
                'Повторений',
              ]
              head.push(tableHead)

              vals.forEach(({ count, name }: any) => {
                body.push([name, count])
              })

              autoTable(doc, {
                head: [...head],
                body: [...body],
                startY: idx ? currentY : currentY + 20,
                headStyles: {
                  font: 'Roboto-Regular-normal',
                  halign: 'justify',
                },
                bodyStyles: {
                  font: 'Roboto-Regular-normal',
                  halign: 'justify',
                },
                columnStyles: {
                  1: {
                    cellWidth: 68,
                    halign: 'right',
                  },
                },
              })

              const tableHeight = (body.length + head.length) * 35 + 20

              if (
                currentY + tableHeight > pageHeight ||
                pageHeight - currentY - tableHeight < 40
              ) {
                const rest = pageHeight - currentY - tableHeight
                currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
                if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
                  doc.addPage()
                }
              } else {
                currentY += tableHeight
              }

              head = []
              body = []
            }
          })
      }
    }

    const renderPhotosArray = (array: string[]) => {
      const formattedBody = splitArrayToRowInputArr(array, 3)

      const photoSize = pageWidth / 3 - 24

      formattedBody.forEach((row: string[]) => {
        const isnotEnoughSpace =
          currentY + photoSize > pageHeight ||
          pageHeight - currentY - photoSize < 12

        if (isnotEnoughSpace) {
          doc.addPage()
          currentY = 20
        }

        row.forEach((img: string, idx: number) => {
          if (idx === 0) {
            doc.addImage(img, 22, currentY, photoSize, photoSize)
          }
          if (idx === 1) {
            doc.addImage(
              img,
              22 + photoSize + 12,
              currentY,
              photoSize,
              photoSize
            )
          }
          if (idx === 2) {
            doc.addImage(
              img,
              22 + (2 * pageWidth) / 3 - 24,
              currentY,
              photoSize,
              photoSize
            )
          }
        })

        currentY += photoSize + 12
      })
    }

    const renderTableFromStringArr = (arr: string[]) => {
      const body = splitArrayToRowInputArr(arr, 4)

      if (!body.length) {
        return
      }

      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      autoTable(doc, {
        body: [...body],
        startY: currentY,
        bodyStyles: {
          font: 'Roboto-Regular-normal',
          halign: 'center',
          valign: 'middle',
        },
      })

      const tableHeight = body.length * 35 + 20

      if (
        currentY + tableHeight > pageHeight ||
        pageHeight - currentY - tableHeight < 40
      ) {
        const rest = pageHeight - currentY - tableHeight
        currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
          doc.addPage()
        }
      } else {
        currentY += tableHeight
      }
    }

    addHeader('Личная информация')

    if (userPhotosBase64?.length) {
      renderPhotosArray(userPhotosBase64)
    }

    renderProfileData(0, 2)

    if (additionalInfo?.getcontact?.length) {
      addSubheader('Get Contact')
      renderTableFromStringArr(additionalInfo?.getcontact)
    }

    if (additionalInfo?.cdek?.names?.length) {
      addSubheader('CDEK')
      renderTableFromStringArr(additionalInfo.cdek.names)
    }

    renderProfileData(2, 3)

    if (socProfData?.length) {
      addSubheader('Социальный профиль')

      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      autoTable(doc, {
        body: [...socProfData],
        startY: currentY,
        bodyStyles: {
          font: 'Roboto-Regular-normal',
          halign: 'justify',
          valign: 'middle',
        },
      })

      const tableHeight = socProfData.length * 35

      if (
        currentY + tableHeight > pageHeight ||
        pageHeight - currentY - tableHeight < 40
      ) {
        if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
          doc.addPage()
        }
        const rest = pageHeight - currentY - tableHeight
        currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
      } else {
        currentY += tableHeight
      }
    }

    renderProfileData(3, 6)

    if (registration || communities?.length || geoInfo?.length) {
      addSubheader('Телеграм')

      if (tgId) {
        addPlainText({ text: `Телеграм ID ${tgId}`, x: 40, y: currentY })
      }

      if (tgUserName) {
        addPlainText({
          text: `Телеграм Никнейм ${tgUserName}`,
          x: 40,
          y: currentY,
        })
      }

      if (registration) {
        addPlainText({
          text: `Дата регистрации ${registration}`,
          x: 40,
          y: currentY,
        })
      }

      if (geoInfo?.length) {
        addSubheader('Локации телеграм')

        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        const head: RowInput[] = [['Локация', 'Ссылка']]
        const body: RowInput[] = []

        geoInfo.forEach(({ geo_info, link }: GeoInfo) => {
          if (geo_info) {
            body.push([geo_info, `https://${link}`])
          }
        })

        autoTable(doc, {
          head,
          body,
          startY: currentY,
          headStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'right',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              halign: 'left',
            },
            1: {
              halign: 'right',
              cellWidth: 100,
            },
          },
        })

        const tableHeight = (body.length + head.length) * 35

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
        } else {
          currentY += tableHeight
        }
      }

      if (communities?.length) {
        addSubheader('Сообщества Телеграм')

        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        const head: RowInput[] = [['Сообщество']]
        const body: RowInput[] = []

        communities.forEach((comm) => {
          body.push([comm])
        })

        autoTable(doc, {
          head,
          body,
          startY: currentY,
          headStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'right',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              halign: 'left',
            },
            1: {
              halign: 'right',
              cellWidth: 70,
            },
          },
        })

        const tableHeight = (body.length + head.length) * 35

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
        } else {
          currentY += tableHeight
        }
      }
    }

    if (changes?.length) {
      addSubheader('Изменения профиля Tg', subheaderML, currentY, true)

      let body: RowInput[] = []

      changes.forEach((change) => {
        Object.entries(change).forEach(([key, val]) => {
          body.push([translatdeTgChangeKeys[key as keyof TgChange], val])
        })

        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        autoTable(doc, {
          body,
          startY: currentY,
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = body.length * 35

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
        } else {
          currentY += tableHeight
        }

        body = []
      })
    }

    if (servers?.length) {
      addSubheader('Возможные сервера')
      renderTableFromStringArr(servers)
    }

    if (subscribedChannels || notSubscribedChannels) {
      currentY += 20
      addSubheader('Сообщения в Telegram')

      const renderPosts = (posts: any) => {
        if (posts?.length) {
          const head: RowInput[] = [['Дата сообщения', 'Текст сообщения']]
          const body: RowInput[] = []

          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          posts.forEach(({ date, message }: any) => {
            const _date = date ? `${transformDate(date)}: ` : '---'
            const _message = message ?? '---'

            body.push([_date, _message])
          })

          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }
      }

      if (subscribedChannels) {
        addSubheader('Подписки Tg', subheaderML, currentY, true)

        subscribedChannels.forEach(
          ({ posts, participants, posts_count, title }: any) => {
            addPlainText({
              text: `Название канала: ${title}`,
              x: 40,
              y: currentY,
            })
            addPlainText({
              text: `Подписчиков в канале: ${participants}`,
              x: 40,
              y: currentY,
            })
            addPlainText({ text: `${posts_count} сбщ.`, x: 40, y: currentY })

            if (posts?.length) {
              renderPosts(posts)
            }
          }
        )
      }

      if (notSubscribedChannels) {
        addSubheader('Подписки Tg', subheaderML, currentY, true)

        notSubscribedChannels.forEach(
          ({ posts, participants, posts_count, title }: any) => {
            addPlainText({
              text: `Название канала: ${title}`,
              x: 40,
              y: currentY,
            })
            addPlainText({
              text: `Подписчиков в канале: ${participants}`,
              x: 40,
              y: currentY,
            })
            addPlainText({ text: `${posts_count} сбщ.`, x: 40, y: currentY })

            if (posts?.length) {
              renderPosts(posts)
            }
          }
        )
      }
    }

    if (vk && Object.keys(vk)?.length) {
      addSubheader('VK')

      const body: RowInput[] = []

      if (vkId) {
        body.push(['VK ID', vkId])
      }

      Object.entries(vk).forEach(([key, val]: any) =>
        body.push([vkKeys?.[key] ?? key, val])
      )

      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      autoTable(doc, {
        body,
        startY: currentY,
        bodyStyles: {
          font: 'Roboto-Regular-normal',
          halign: 'justify',
          valign: 'middle',
        },
        columnStyles: {
          0: {
            cellWidth: 200,
          },
          1: {
            halign: 'right',
          },
        },
      })

      const tableHeight = body.length * 35

      if (
        currentY + tableHeight > pageHeight ||
        pageHeight - currentY - tableHeight < 40
      ) {
        if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
          doc.addPage()
        }
        const rest = pageHeight - currentY - tableHeight
        currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
      } else {
        currentY += tableHeight
      }
    }

    if (combinedApps?.length) {
      addSubheader('Приложения')

      const body: RowInput[] = []
      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      combinedApps.forEach((app: any) => {
        if (app) {
          Object.entries(app)
            .filter((i) => i?.[0] !== 'data_value' && i?.[0] !== 'data_nm')
            .forEach(([key, val]: any) => {
              if (translateAppKey(key) === 'название приложения') {
                addSubheader(val, subheaderML, currentY, true)
              } else {
                body.push([translateAppKey(key), val])
              }
            })
        }
      })

      if (body.length) {
        autoTable(doc, {
          body,
          startY: currentY,
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = body.length * 35

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        } else {
          currentY += tableHeight
        }
      }
    }

    if (relations && Object.keys(relations)?.length) {
      addSubheader('Связки')

      const body: RowInput[] = []

      Object.entries(relations).forEach(([key, val]: any) => {
        if (
          key &&
          val &&
          !key?.includes('hash') &&
          !key?.includes('period_from')
        ) {
          body.push([translateRelKey(key), val])
        }
      })

      if (body.length) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        autoTable(doc, {
          body,
          startY: currentY,
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = body.length * 35

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        } else {
          currentY += tableHeight
        }
      }
    }

    if (additionalInfo?.additional_labels?.length) {
      addSubheader('Метки')
      renderTableFromStringArr(additionalInfo.additional_labels)
    }

    if (
      additionalInfo?.yandex_food?.orders &&
      Object.keys(additionalInfo.yandex_food.orders)?.length
    ) {
      let text = 'Yandex Food'
      if (additionalInfo?.yandex_food?.amount_spent) {
        text = `Yandex Food (${additionalInfo?.yandex_food?.amount_spent} ₽)`
      }
      addSubheader(text)

      Object.entries(additionalInfo.yandex_food.orders)?.forEach(
        ([address, ordersObj]: any) => {
          addSubheader(address, subheaderML, currentY, true)

          if (ordersObj?.add_info?.length) {
            addPlainText({ text: 'Инфо об адресе', x: 40, y: currentY })
            const addressInfo = ordersObj.add_info.map(
              (addInfo: string) => `${addInfo} `
            )
            addPlainText({
              text: addressInfo.join(' '),
              x: 40,
              y: currentY,
              options: { maxWidth: pageWidth - 80 },
            })
          }

          if (ordersObj?.order?.length) {
            const head: RowInput[] = [
              ['№ Заказа', 'Стоимость', 'Дата', 'Ресторан'],
            ]
            const body: RowInput[] = []

            const initialPageNumber = doc.getCurrentPageInfo().pageNumber

            ordersObj.order.forEach((orderInfo: any, orderInfoIdx: number) => {
              body.push([
                orderInfoIdx + 1,
                orderInfo?.price ?? '---',
                orderInfo?.date ?? '---',
                orderInfo?.place_name ?? '---',
              ])
            })

            autoTable(doc, {
              head,
              body,
              startY: currentY,
              headStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
              },
              bodyStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
                valign: 'middle',
              },
              columnStyles: {
                0: {
                  cellWidth: 200,
                },
                1: {
                  halign: 'right',
                },
              },
            })

            const tableHeight = (body.length + head.length) * 35

            if (
              currentY + tableHeight > pageHeight ||
              pageHeight - currentY - tableHeight < 40
            ) {
              if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
                doc.addPage()
              }
              const rest = pageHeight - currentY - tableHeight
              currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
            } else {
              currentY += tableHeight
            }
          }
        }
      )
    }

    if (
      additionalInfo?.delivery_food?.orders &&
      Object.keys(additionalInfo.delivery_food.orders)?.length
    ) {
      let text = 'Delivery Club'
      if (additionalInfo?.delivery_food?.amount_spent) {
        text = `Delivery Club (${additionalInfo?.delivery_food?.amount_spent} ₽)`
      }
      addSubheader(text)

      Object.entries(additionalInfo.delivery_food.orders).forEach(
        ([address, ordersArr]: any) => {
          addSubheader(address, subheaderML, currentY, true)

          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          if (ordersArr?.length) {
            const head: RowInput[] = [
              ['№ Заказа', 'Стоимость', 'Дата', 'Ресторан'],
            ]
            const body: RowInput[] = []

            ordersArr.forEach((orderInfo: any, orderInfoIdx: number) => {
              body.push([
                orderInfoIdx + 1,
                orderInfo?.price ?? '---',
                orderInfo?.date ?? '---',
                orderInfo?.name ?? '---',
              ])
            })

            autoTable(doc, {
              head,
              body,
              startY: currentY,
              headStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
              },
              bodyStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
                valign: 'middle',
              },
              columnStyles: {
                0: {
                  cellWidth: 200,
                },
                1: {
                  halign: 'right',
                },
              },
            })

            const tableHeight = (body.length + head.length) * 35

            if (
              currentY + tableHeight > pageHeight ||
              pageHeight - currentY - tableHeight < 40
            ) {
              if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
                doc.addPage()
              }
              const rest = pageHeight - currentY - tableHeight
              currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
            } else {
              currentY += tableHeight
            }
          }
        }
      )
    }

    if (
      type !== 'profile_telegram_id' &&
      type !== 'profile_vk_id' &&
      type !== 'profile_telegram_user_name'
    ) {
      if (
        autoPhotosBase64?.length ||
        tripPhotosTableData ||
        (transportInfo && Object.keys(transportInfo)?.length) ||
        grzData ||
        phoneData ||
        (finesData && Object.keys(finesData)?.length) ||
        accidents ||
        hasSummary ||
        (avtocodData && Object.keys(avtocodData)?.length) ||
        autoPairsData?.length ||
        gibddData ||
        insurance ||
        possibleSelectors?.length
      ) {
        doc.addPage()
        currentY = 40
        addHeader('Транспортные данные')
      }
    }

    if (autoPhotosBase64?.length) {
      addSubheader('Фото транспорта')
      renderPhotosArray(autoPhotosBase64)
    }

    if (tripPhotosTableData) {
      if (currentY > 100) {
        doc.addPage()
        currentY = 40
      } else {
        currentY += 20
      }

      addHeader('Фотофиксации')

      const height = tripPhotosTableData?.data?.length * 190 + 20
      const rest = (height % pageHeight) - 40

      autoTable(doc, {
        html: tableRef.current.firstChild.firstChild.firstChild.firstChild
          .firstChild.firstChild,
        pageBreak: 'auto',
        tableWidth: 'wrap',
        startY: currentY,
        headStyles: {
          font: 'Roboto-Regular-normal',
          halign: 'center',
          valign: 'middle',
        },
        bodyStyles: {
          minCellHeight: 145,
          font: 'Roboto-Regular-normal',
          halign: 'center',
          valign: 'top',
          cellPadding: 25,
          cellWidth: 170,
          fontStyle: 'bolditalic',
        },
        didDrawCell: (data) => {
          if (data.column.index === 0 && data.cell.section === 'body') {
            const td = data.cell.raw
            // @ts-ignore
            const img = td.getElementsByTagName('img')[0]
            if (img) {
              doc.addImage(img.src, data.cell.x + 15, data.cell.y + 45, 130, 50)
            }
          }
          if (data.column.index === 2 && data.cell.section === 'body') {
            const td = data.cell.raw
            // @ts-ignore
            const img = td.getElementsByTagName('img')[0]
            if (img) {
              doc.addImage(img.src, data.cell.x + 35, data.cell.y + 5, 130, 135)
            }
          }
        },
      })

      if (rest < 40) {
        doc.addPage()
        currentY = 20
      } else {
        currentY = rest + 110
      }
    }

    if (transportInfo && Object.keys(transportInfo)?.length) {
      if (currentY > 100) {
        doc.addPage()
        currentY = 40
        addSubheader('Транспортные данные')
      } else {
        currentY += 20
      }

      Object.entries(transportInfo).forEach(([name, vals]: any) => {
        if (vals?.length) {
          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          const head: RowInput[] = [
            [transportFields?.[name].name, 'Повторений'],
          ]
          const body: RowInput[] = []

          vals.forEach(({ name, count }: any) => body.push([name, count]))

          if (body.length) {
            autoTable(doc, {
              head,
              body,
              startY: currentY,
              headStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
                valign: 'middle',
              },
              bodyStyles: {
                font: 'Roboto-Regular-normal',
                halign: 'justify',
                valign: 'middle',
              },
              columnStyles: {
                1: {
                  cellWidth: 68,
                  halign: 'right',
                },
              },
            })

            const tableHeight = (head.length + body.length) * 35

            if (
              currentY + tableHeight > pageHeight ||
              pageHeight - currentY - tableHeight < 40
            ) {
              if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
                doc.addPage()
              }
              const rest = pageHeight - currentY - tableHeight
              currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
            } else {
              currentY += tableHeight
            }
          }
        }
      })
    }

    if (grzData) {
      addSubheader(`Связки парковочных сессий ${grzData?.search}`)

      const getDate = (dateString: string) => {
        const dateAndTime = dateString.split('T')

        const [YYYY, MM, DD] = dateAndTime[0].split('-')

        return `${DD}.${MM}.${YYYY}`
      }

      grzData.parking.forEach((parking: any) =>
        Object.entries(parking).forEach(([pKey, pValue]: any) => {
          const head: RowInput[] = []
          const body: RowInput[] = []

          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          head.push([pKey, ''])

          if (pValue?.total_relation_cnt) {
            body.push([
              `Парковки ${grzData?.search}`,
              pValue.total_relation_cnt,
            ])
          }

          if (pValue?.max_dt) {
            body.push(['Последняя парковка', getDate(pValue.max_dt)])
          }

          if (pValue?.grz?.length) {
            pValue.grz.forEach((grzInfo: any) => {
              if (grzInfo?.grz || grzInfo?.info?.total_relation_cnt) {
                body.push([
                  `Парковки ${grzInfo?.grz ?? '---'}`,
                  grzInfo?.info?.total_relation_cnt ?? '---',
                ])
              }

              if (grzInfo?.info?.max_dt) {
                body.push(['Последняя парковка', getDate(grzInfo.info.max_dt)])
              }
            })
          }

          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + 1) * 35

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        })
      )
    }

    if (phoneData) {
      addSubheader(`Связки парковочных сессий ${phoneData?.search}`)

      const getDate = (dateString: string) => {
        const dateAndTime = dateString.split('T')

        const [YYYY, MM, DD] = dateAndTime[0].split('-')

        return `${DD}.${MM}.${YYYY}`
      }

      phoneData.parking.forEach((parking: any) =>
        Object.entries(parking).forEach(([pKey, pValue]: any) => {
          const head: RowInput[] = []
          const body: RowInput[] = []

          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          head.push([pKey, ''])

          if (pValue?.total_relation_cnt) {
            body.push([
              `Парковок с телефона ${phoneData?.search}`,
              pValue.total_relation_cnt,
            ])
          }

          if (pValue?.max_dt) {
            body.push(['Последняя парковка', getDate(pValue.max_dt)])
          }

          if (pValue?.phones?.length) {
            pValue.phones.forEach((phoneInfo: any) => {
              if (phoneInfo?.phone || phoneInfo?.info?.total_relation_cnt) {
                body.push([
                  `Парковки ${phoneInfo?.phone ?? '---'}`,
                  phoneInfo?.info?.total_relation_cnt ?? '---',
                ])
              }

              if (phoneInfo?.info?.max_dt) {
                body.push([
                  'Последняя парковка',
                  getDate(phoneInfo.info.max_dt),
                ])
              }
            })
          }

          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + 1) * 35

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        })
      )
    }

    if (finesData && Object.keys(finesData)?.length) {
      addSubheader('Штрафы')

      Object.entries(finesData).forEach(([year, finesInYear]: any) => {
        if (finesInYear?.length) {
          const initialPageNumber = doc.getCurrentPageInfo().pageNumber

          const head: RowInput[] = [[`Штрафы ${fines?.regno} в ${year} г`, '']]
          const body: RowInput[] = []

          finesInYear.forEach((fine: any, fineIdx: number) => {
            if (
              fine?.vehicle_model_array?.length &&
              fine?.vehicle_model_array.some((el: any) => !!el) &&
              fineIdx === 0
            ) {
              const models = fine?.vehicle_model_array
                .filter((el: any) => el !== 'nan')
                .map((model: string, modelIdx: number, arr: any) => {
                  return `${model} ${modelIdx !== arr.length - 1 ? '; ' : ''}`
                })

              body.push(['Модели авто', models])
            }

            if (fine?.violation_desc || fine?.violation_cnt) {
              if (fine?.violation_desc === 'Суммарные данные') {
                body.push(['Всего нарушений', fine?.violation_cnt ?? '---'])
              } else {
                const descr =
                  fine?.violation_desc ?? 'Неизвестный тип нарушения'
                const cnt = fine?.violation_cnt ?? '---'

                body.push([descr, cnt])
              }
            }
          })

          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 400,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + 1) * 35

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }
      })
    }

    if (accidents) {
      accidents.forEach((acc) => {
        addSubheader('ДТП')

        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        const head: RowInput[] = [[`ДТП ${getFineDay(acc?.day)}`, '']]
        const body: RowInput[] = []

        if (acc?.accident_type_nm) {
          body.push(['Тип ДТП', acc.accident_type_nm])
        }

        if (
          acc?.accident_location ||
          acc?.accident_latitude ||
          acc?.accident_longitude
        ) {
          body.push([
            'Локация',
            `${acc?.accident_location} ${
              acc?.building_num ? `д.${acc.building_num}` : ''
            }` || `${acc?.accident_latitude} - ${acc?.accident_longitude}`,
          ])
        }

        if (acc?.damage_desc) {
          body.push(['Описание повреждений', acc.damage_desc])
        }

        if (acc?.damage_disposition_array?.length) {
          const damages = acc.damage_disposition_array
            .map((dam: any) => `${dam};`)
            .join('')

          body.push(['Список повреждений', damages])
        }

        if (
          (acc?.died_cnt && acc?.died_cnt !== '0') ||
          (acc?.died_child_cnt && acc?.died_child_cnt !== '0')
        ) {
          body.push(['Количество погибших', acc?.died_cnt])
          body.push(['Количество погибших детей', acc?.died_child_cnt])
        }

        if (
          (acc?.injured_cnt && acc?.injured_cnt !== '0') ||
          (acc?.injured_child_cnt && acc?.injured_child_cnt !== '0')
        ) {
          body.push(['Количество пострадавших', acc?.injured_cnt])
          body.push(['Количество пострадавших детей', acc?.injured_child_cnt])
        }

        if (acc?.person_escape) {
          body.push(['Скрылся ли с места ДТП', acc.person_escape])
        }

        if (acc?.phone) {
          body.push(['Телефон', acc.phone])
        }

        if (acc?.person_residence_address) {
          body.push(['Адрес проживания', acc.person_residence_address])
        }

        if (acc?.regno) {
          body.push(['ГРЗ', acc.regno])
        }

        if (acc?.vehicle_mark_nm) {
          body.push(['Модель авто', acc.vehicle_mark_nm])
        }

        if (acc?.street_nm) {
          body.push(['Улица', acc.street_nm])
        }

        if (acc?.person_birthday) {
          body.push(['День рождения', acc.person_birthday])
        }

        if (body.length) {
          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = body.length * 35

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }
      })
    }

    if (hasSummary) {
      addSubheader('Передвижения на транспорте')

      let head: RowInput[] = []
      let body: RowInput[] = []

      if (
        summary?.top_auto_parking_location?.camera_address ||
        (summary?.top_auto_parking_location?.location_lat &&
          summary?.top_auto_parking_location?.location_lon)
      ) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push(['Наиболее частая локация парковки', ''])
        body.push([
          summary?.top_auto_parking_location?.camera_address ||
            `${summary?.top_auto_parking_location?.location_lat} - ${summary?.top_auto_parking_location?.location_lon}`,
          summary?.top_auto_parking_location?.parking_count ?? '---',
        ])

        if (body.length) {
          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (
        summary?.top_auto_route?.start_camera_address ||
        summary?.top_auto_route?.end_camera_address
      ) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push(['Наиболее популярный маршрут поездки', ''])
        body.push([
          summary?.top_auto_route?.start_camera_address ?? '---',
          summary?.top_auto_route?.end_camera_address ?? '---',
        ])

        if (body.length) {
          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.top_auto_route?.total_trip_count) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        body.push([
          'Количество поездок',
          `${summary?.top_auto_route?.total_trip_count ?? '---'}`,
        ])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = body.length * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.top_districts?.length) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push(['Наиболее популярные районы', ''])
        summary.top_districts.forEach(({ district, cnt }: any) => {
          body.push([district, cnt])
        })

        if (body.length) {
          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (
        summary?.top_start_subway_station ||
        summary?.top_end_subway_station
      ) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        body.push([
          'Популярная станция начала поездки метро',
          `${
            `м.${summary?.top_start_subway_station}` ||
            summary?.top_start_subway_line
          }`,
        ])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = body.length * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.top_start_subway_line || summary?.top_end_subway_line) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push([
          'Популярная станция окончания поездки метро',
          `${
            `м.${summary?.top_end_subway_station}` ||
            summary?.top_end_subway_line
          }`,
        ])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = body.length * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (
        summary?.top_subway_route?.end_line ||
        summary?.top_subway_route?.end_station ||
        summary?.top_subway_route?.start_line ||
        summary?.top_subway_route?.start_station
      ) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push(['Популярный маршрут поездки метро', ''])
        body.push([
          `м.${summary?.top_subway_route?.start_station}` ||
            summary?.top_subway_route?.start_line,

          `м.${summary?.top_subway_route?.end_station}` ||
            summary?.top_subway_route?.end_line,
        ])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.total_subway_trips) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        body.push(['Всего поездок на метро', summary.total_subway_trips])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.top_start_ngpt_station || summary?.top_end_ngpt_station) {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        head.push(['Популярный маршрут НГПТ', ''])
        body.push([
          `ост.${summary?.top_start_ngpt_station}`,
          `ост.${summary?.top_end_ngpt_station}`,
        ])

        if (body.length) {
          autoTable(doc, {
            body,
            startY: currentY,
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + head.length) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }

        head = []
        body = []
      }

      if (summary?.total_ngpt_trips) {
        body.push(['Всего поездок на НГПТ', summary.total_ngpt_trips])
      }
    }

    if (avtocodData && Object.keys(avtocodData)?.length) {
      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      const head: RowInput[] = [['Информация об авто', '']]
      const body: RowInput[] = []

      if (avtocodData?.grz) {
        body.push(['ГРЗ', avtocodData.grz])
      }

      if (avtocodData?.model) {
        body.push(['Модель авто', avtocodData.model])
      }

      if (avtocodData?.year_of_production) {
        body.push(['Год производства', avtocodData.year_of_production])
      }

      if (avtocodData?.model) {
        body.push(['Расположение руля', avtocodData.model])
      }

      if (avtocodData?.engine_capacity) {
        body.push(['Объем двигателя', avtocodData.engine_capacity])
      }

      if (avtocodData?.engine_power) {
        body.push(['Мощность двигателя', avtocodData.engine_power])
      }

      if (avtocodData?.engine_type) {
        body.push(['Тип двигателя', avtocodData.engine_type])
      }

      if (body.length) {
        autoTable(doc, {
          head,
          body,
          startY: currentY,
          headStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = (body.length + 1) * 35 + 20

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        } else {
          currentY += tableHeight
        }
      }
    }

    if (autoPairsData?.length) {
      addSubheader('Автопары', subheaderML, currentY)

      autoPairsData.forEach((pair: any, pairIdx: number) => {
        const initialPageNumber = doc.getCurrentPageInfo().pageNumber

        const head: RowInput[] = [['Пара №', pairIdx]]
        const body: RowInput[] = []

        if (pair?.regno) {
          body.push(['ГРЗ пользователя', pair.regno])
        }

        if (pair?.pair_regno) {
          body.push(['ГРЗ пары', pair?.pair_regno])
        }

        if (pair?.total_trip_cnt) {
          body.push([
            'Количество дней с совместными поездками',
            pair.total_trip_cnt,
          ])
        }

        if (pair?.total_trip_cnt) {
          body.push(['Количество совместных поездок', pair.total_trip_cnt])
        }

        if (pair?.rush_hour_trip_cnt) {
          body.push(['Поездок в час пик', pair.rush_hour_trip_cnt])
        }

        if (pair?.non_rush_hour_trip_cnt) {
          body.push(['Поездок не в час пик', pair?.non_rush_hour_trip_cnt])
        }

        if (pair?.pair_cnt) {
          body.push(['Количество пар', pair.pair_cnt])
        }

        if (body.length) {
          autoTable(doc, {
            head,
            body,
            startY: currentY,
            headStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            bodyStyles: {
              font: 'Roboto-Regular-normal',
              halign: 'justify',
              valign: 'middle',
            },
            columnStyles: {
              0: {
                cellWidth: 200,
              },
              1: {
                halign: 'right',
              },
            },
          })

          const tableHeight = (body.length + 1) * 35 + 20

          if (
            currentY + tableHeight > pageHeight ||
            pageHeight - currentY - tableHeight < 40
          ) {
            if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
              doc.addPage()
            }
            const rest = pageHeight - currentY - tableHeight
            currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
          } else {
            currentY += tableHeight
          }
        }
      })
    }

    if (gibddData) {
      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      const head: RowInput[] = [['Данные ГИБДД', '']]
      const body: RowInput[] = []

      gibddData.forEach(([key, value]: any) => {
        if (translateGibdd?.[key]) {
          body.push([translateGibdd[key].text, value])
        }
      })

      if (body.length) {
        autoTable(doc, {
          head,
          body,
          startY: currentY,
          headStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = (body.length + 1) * 35 + 20

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        } else {
          currentY += tableHeight
        }
      }
    }

    if (insurance) {
      const initialPageNumber = doc.getCurrentPageInfo().pageNumber

      const head: RowInput[] = [['Страховой полис', '']]
      const body: RowInput[] = []

      insurance.forEach(([key, value]: any) => {
        if (translateInsurance?.[key]) {
          body.push([translateInsurance[key].text, value])
        }
      })

      if (body.length) {
        autoTable(doc, {
          head,
          body,
          startY: currentY,
          headStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          bodyStyles: {
            font: 'Roboto-Regular-normal',
            halign: 'justify',
            valign: 'middle',
          },
          columnStyles: {
            0: {
              cellWidth: 200,
            },
            1: {
              halign: 'right',
            },
          },
        })

        const tableHeight = (body.length + 1) * 35 + 20

        if (
          currentY + tableHeight > pageHeight ||
          pageHeight - currentY - tableHeight < 40
        ) {
          if (initialPageNumber === doc.getCurrentPageInfo().pageNumber) {
            doc.addPage()
          }
          const rest = pageHeight - currentY - tableHeight
          currentY = Math.abs(rest) + 110 // таблица перейдет на новую страницу и займет там 2 строки
        } else {
          currentY += tableHeight
        }
      }
    }

    if (possibleSelectors?.length) {
      const activeBtns = possibleSelectors.filter((id) => {
        const ID = id?.slice(0, id?.indexOf('-'))

        const btn = document.getElementById(ID)

        return btn?.dataset.id === 'On'
      })

      setInitialActiveBtns(activeBtns)

      const btns = possibleSelectors.map((id) => {
        const ID = id?.slice(0, id?.indexOf('-'))

        const btn = document.getElementById(ID)

        if (btn?.dataset.id === 'On') {
          btn.click()
        }

        return btn
      }) as HTMLElement[]

      setIsReadyToAddNewScreen(true)
      setBtns(btns)
      setDoc(doc)
    } else {
      setTimeout(() => {
        doc.save(`${new Date().toLocaleString()}.pdf`)
        setIsSaving(false)
      }, 100)
    }
  }, [
    accidents,
    additionalInfo?.additional_labels,
    additionalInfo?.cdek?.names,
    additionalInfo?.delivery_food?.amount_spent,
    additionalInfo?.delivery_food?.orders,
    additionalInfo?.getcontact,
    additionalInfo?.yandex_food?.amount_spent,
    additionalInfo?.yandex_food?.orders,
    autoPairsData,
    autoPhotosBase64,
    avtocodData,
    changes,
    combinedApps,
    communities,
    fines?.regno,
    finesData,
    geoInfo,
    getFineDay,
    gibddData,
    grzData,
    hasSummary,
    insurance,
    normalizedProfileData,
    notSubscribedChannels,
    phoneData,
    possibleSelectors,
    registration,
    relations,
    servers,
    setIsSaving,
    socProfData,
    subscribedChannels,
    summary?.top_auto_parking_location?.camera_address,
    summary?.top_auto_parking_location?.location_lat,
    summary?.top_auto_parking_location?.location_lon,
    summary?.top_auto_parking_location?.parking_count,
    summary?.top_auto_route?.end_camera_address,
    summary?.top_auto_route?.start_camera_address,
    summary?.top_auto_route?.total_trip_count,
    summary?.top_districts,
    summary?.top_end_ngpt_station,
    summary?.top_end_subway_line,
    summary?.top_end_subway_station,
    summary?.top_start_ngpt_station,
    summary?.top_start_subway_line,
    summary?.top_start_subway_station,
    summary?.top_subway_route?.end_line,
    summary?.top_subway_route?.end_station,
    summary?.top_subway_route?.start_line,
    summary?.top_subway_route?.start_station,
    summary?.total_ngpt_trips,
    summary?.total_subway_trips,
    tableRef,
    tgId,
    tgUserName,
    translateGibdd,
    translateInsurance,
    transportInfo,
    tripPhotosTableData,
    type,
    userPhotosBase64,
    vk,
    vkId,
  ])

  useEffect(() => {
    if (!btns?.length) {
      return
    }

    const legend = document.getElementById('legend')
    const map = document.getElementById('map')
    const mapWithPallete = document.getElementById('mapWithPallete')

    const screensWithPallete = ['routes', 'districts']

    const layer = btns[0]?.id

    setScreeningLayer(layer)

    const mapHtmlEl = screensWithPallete.includes(layer) ? mapWithPallete : map

    if (doc) {
      if (mapHtmlEl && isReadyToAddNewScreen) {
        const pageHeight =
          doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
        const pageWidth =
          doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

        const addSubheader = (text: string) => {
          doc.setFontSize(16)
          doc.text(text, 40, 40)
        }

        doc.addPage()

        if (layer) {
          const name = mapLayers?.[layer] as string
          addSubheader(name)
        }

        setIsReadyToAddNewScreen(false)

        btns[0].click() // включил новый слой

        setTimeout(() => {
          html2canvas(mapHtmlEl).then((canvas) => {
            const imgWidth = pageWidth - 12
            const imgX = 20

            const imgHeight =
              window.innerWidth < 700 ? pageHeight * 0.9 : pageHeight * 0.4

            const contentDataURL = canvas.toDataURL('image/png')

            doc.addImage(
              contentDataURL,
              'PNG',
              imgX,
              60,
              imgWidth - 30,
              imgHeight
            )

            btns[0].click() // выключил слой

            if (btns.length > 1) {
              setBtns(btns.slice(1))
              setIsReadyToAddNewScreen(true)
            }

            if (btns.length === 1) {
              setBtns(null)

              if (legend) {
                doc.addPage()

                addSubheader('Легенда')

                setTimeout(() => {
                  html2canvas(legend).then((canvas) => {
                    const imgWidth = pageWidth - 12
                    const imgX = 20

                    const imgHeight =
                      window.innerWidth < 700
                        ? pageHeight * 0.9
                        : pageHeight * 0.6

                    const contentDataURL = canvas.toDataURL('image/png')

                    doc.addImage(
                      contentDataURL,
                      'PNG',
                      imgX,
                      60,
                      imgWidth - 30,
                      imgHeight
                    )

                    doc.save(`${new Date().toLocaleString()}.pdf`)
                    setIsSaving(false)

                    initialActiveBtns.forEach((btn) => {
                      const ID = btn.slice(0, btn.indexOf('-'))
                      const BTN = document.getElementById(ID)
                      BTN?.click()
                    })
                  })
                }, 100)
              }
            }
          })
        }, 100)
      }
    }
  }, [
    btns,
    doc,
    initialActiveBtns,
    setScreeningLayer,
    setIsSaving,
    isReadyToAddNewScreen,
  ])

  return { handleDownloadPdf }
}

import { Box, Button, Stack, Typography } from '@mui/material'
import { Context } from 'index'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useContext, useMemo } from 'react'
import s from './PersonalInfo.module.scss' //TODO
import TelegramIcon from '@mui/icons-material/Telegram'
import LanIcon from '@mui/icons-material/Lan'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'components/common/Accordion'
import { GeoInfo, TgChange } from 'store/profileStore/profileStore.types'
import { InfoChip } from 'components/Profile/components/InfoChip'
import { TgInsights } from './TgInsights'

const translatdeTgChangeKeys: TgChange = {
  name: 'Имя',
  date: 'Дата',
  user_name: 'Имя Пользователя',
}

const translatedTgKeys: Record<string, string> = {
  name: 'Имя',
}

export interface PersonalInfoProps {
  expanded: string | false
  handleChange: (
    panel: string
  ) => (_: SyntheticEvent, newExpanded: boolean) => void
}

export const TgPersonalInfo = observer(
  ({ expanded, handleChange }: PersonalInfoProps) => {
    const { rootStore } = useContext(Context)
    const { profileStore } = rootStore
    const {
      links: { tgId, tgUserName },
      tgPhoneUserData,
      tgData: {
        registration,
        geoInfo,
        communities,
        changes,
        servers,
        historyPastUsers,
      },
      insights,
    } = profileStore

    const getHref = useMemo(() => {
      return tgId &&
        (String(tgId)?.startsWith('t.me') || String(tgId)?.startsWith('http'))
        ? tgId
        : `https://t.me/${tgId}`
    }, [tgId])

    const isLink = useMemo(() => {
      return tgId && !/^\d+$/.test(tgId)
    }, [tgId])

    if (
      !tgId &&
      !tgUserName &&
      !registration &&
      !geoInfo.length &&
      !communities.length &&
      !insights &&
      (!changes || (changes && !changes.length)) &&
      (!servers || (servers && !servers.length)) &&
      (!historyPastUsers || (historyPastUsers && !historyPastUsers.length))
    ) {
      return null
    }

    return (
      <Stack sx={{ px: 2, my: 1 }}>
        <Stack sx={{ width: '100%' }} alignItems='flex-start'>
          <Stack direction='row'>
            <Box
              sx={{
                svg: { color: 'primary.main', fontSize: 22 },
                mr: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TelegramIcon />
            </Box>

            <Typography variant='h6' color='common.black'>
              Телеграм
            </Typography>
          </Stack>

          <Stack sx={{ width: '100%' }}>
            {tgId ? (
              <Stack
                sx={{ width: '100%', p: 1 }}
                direction='row'
                justifyContent='space-between'
              >
                <Typography
                  variant='body1'
                  color='common.black'
                  width='50%'
                  textAlign='start'
                  sx={{ wordBreak: 'break-word' }}
                >
                  Телеграм ID
                </Typography>
                <Typography variant='body1' color='text.disabled'>
                  {isLink ? (
                    <a target='_blank' href={getHref} rel='noreferrer'>
                      {tgId}
                    </a>
                  ) : (
                    <>{tgId}</>
                  )}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          <Stack sx={{ width: '100%' }}>
            {tgUserName ? (
              <Stack
                sx={{ width: '100%', p: 1 }}
                direction='row'
                justifyContent='space-between'
              >
                <Typography
                  variant='body1'
                  color='common.black'
                  width='50%'
                  textAlign='start'
                  sx={{ wordBreak: 'break-word' }}
                >
                  Телеграм Никнейм
                </Typography>
                <Typography variant='body1' color='text.disabled'>
                  {tgUserName}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          {tgPhoneUserData ? (
            <>
              {Object.entries(tgPhoneUserData).map(([key, value]) => (
                <Stack sx={{ width: '100%' }} key={key}>
                  <Stack
                    sx={{ width: '100%', p: 1 }}
                    direction='row'
                    justifyContent='space-between'
                  >
                    <Typography
                      variant='body1'
                      color='common.black'
                      width='50%'
                      textAlign='start'
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {translatedTgKeys?.[key] ?? key}
                    </Typography>
                    <Typography variant='body1' color='text.disabled'>
                      {value as string | number}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </>
          ) : null}

          <Stack sx={{ width: '100%' }}>
            {registration ? (
              <Stack
                sx={{ width: '100%', p: 1 }}
                direction='row'
                justifyContent='space-between'
              >
                <Typography
                  variant='body1'
                  color='common.black'
                  width='50%'
                  textAlign='start'
                  sx={{ wordBreak: 'break-word' }}
                >
                  Дата регистрации
                </Typography>
                <Typography variant='body1' color='text.disabled'>
                  {registration}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          <Stack sx={{ width: '100%' }}>
            {geoInfo?.length ? (
              <Stack>
                <Typography color='common.black' variant='h6' textAlign='start'>
                  Локации Телеграм
                </Typography>
                {geoInfo.map(({ geo_info, link }: GeoInfo, idx: number) => (
                  <Stack
                    key={idx}
                    sx={{ width: '100%', p: 1 }}
                    direction='row'
                    justifyContent='space-between'
                  >
                    <Typography
                      variant='body1'
                      width='50%'
                      textAlign='start'
                      color='common.black'
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {geo_info}
                    </Typography>
                    <Typography variant='body1' color='text.disabled'>
                      <Button
                        variant='outlined'
                        sx={{
                          textTransform: 'none',
                          borderRadius: '6px',
                          ':hover': {
                            border: '1px solid rgba(25, 118, 210, 0.5)',
                          },
                        }}
                      >
                        <a
                          className={s.link}
                          href={`https://${link}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Локация
                        </a>
                      </Button>
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            ) : null}
          </Stack>

          {communities?.length ? (
            <Accordion
              sx={{ width: '100%', mt: 1 }}
              expanded={expanded === 'communities'}
              onChange={handleChange('communities')}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    svg: { color: 'primary.main', fontSize: 22 },
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TelegramIcon />
                </Box>
                <Typography variant='body1' color='common.black'>
                  Сообщества Телеграм
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  justifyContent='space-between'
                  sx={{
                    mt: 1,
                    width: '100%',
                    maxWidth: '900px',
                  }}
                >
                  <Stack direction='row' flexWrap='wrap'>
                    {communities.map((comm, idx) => (
                      <InfoChip key={`${Math.random()}_${idx}`} text={comm} />
                    ))}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {changes?.length ? (
            <Accordion
              sx={{ width: '100%' }}
              expanded={expanded === 'changes'}
              onChange={handleChange('changes')}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    svg: { color: 'primary.main', fontSize: 22 },
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TelegramIcon />
                </Box>
                <Typography variant='body1' color='common.black'>
                  Изменения профиля Tg
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  justifyContent='space-between'
                  sx={{
                    mt: 1,
                    width: '100%',
                    maxWidth: '900px',
                  }}
                >
                  {changes.map((change, changeObjIdx) => (
                    <Stack key={changeObjIdx} sx={{ width: '100%' }}>
                      {Object.entries(change).map(([key, val], changeIdx) => (
                        <Stack
                          p={1}
                          key={`${changeIdx}_${Math.random()}`}
                          direction='row'
                          justifyContent='space-between'
                        >
                          <Typography
                            variant='body1'
                            color='common.black'
                            width='50%'
                            textAlign='start'
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {translatdeTgChangeKeys[key as keyof TgChange]}
                          </Typography>
                          <Typography
                            variant='body1'
                            color='text.disabled'
                            textAlign='end'
                          >
                            {val}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {insights && Object.keys(insights).length ? (
            <TgInsights data={insights.info} />
          ) : null}

          {servers?.length ? (
            <Stack sx={{ width: '100%', mt: 1 }}>
              <Stack direction='row'>
                <Box
                  sx={{
                    svg: { color: 'primary.main', fontSize: 22 },
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LanIcon />
                </Box>

                <Typography variant='h6' color='common.black'>
                  Возможные сервера
                </Typography>
              </Stack>

              <Stack direction='row'>
                {servers.map((server, idx) => (
                  <InfoChip key={`${Math.random()}_${idx}`} text={server} />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {historyPastUsers?.length ? (
            <Accordion
              sx={{ width: '100%' }}
              expanded={expanded === 'pastUsers'}
              onChange={handleChange('pastUsers')}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    svg: { color: 'primary.main', fontSize: 22 },
                    mr: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TelegramIcon />
                </Box>
                <Typography variant='body1' color='common.black'>
                  Прошлые пользователи
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {historyPastUsers.map(({ tg_id, date }, idx, arr) => (
                  <Stack
                    key={Math.random()}
                    sx={{ mb: idx !== arr.length - 1 ? 1 : 0 }}
                  >
                    <Typography
                      fontWeight='bold'
                      variant='body1'
                      textAlign='center'
                    >
                      Пользователь {idx + 1}
                    </Typography>

                    {tg_id ? (
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography
                          variant='body1'
                          color='common.black'
                          width='50%'
                          textAlign='start'
                          sx={{ wordBreak: 'break-word' }}
                        >
                          Telegram Id
                        </Typography>
                        <Typography variant='body1' color='text.disabled'>
                          {tg_id}
                        </Typography>
                      </Stack>
                    ) : null}

                    {tg_id ? (
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography
                          variant='body1'
                          color='common.black'
                          width='50%'
                          textAlign='start'
                          sx={{ wordBreak: 'break-word' }}
                        >
                          Дата
                        </Typography>
                        <Typography variant='body1' color='text.disabled'>
                          {date}
                        </Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Stack>
      </Stack>
    )
  }
)

import { Box, Stack, SxProps } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { Context } from 'index'
import './profile.styles.scss'
import { PersonalInfo } from './PersonalInfo/PersonalInfo/PersonalInfo'
import { useMediaPredicate } from 'react-media-hook'
import { useParams } from 'react-router-dom'
import { Loading } from '../Loading'
import { Map } from '../Map'
import TransportGallery from './TransportGallery/TransportGallery'
import { ProfileTransportInfo } from './ProfileTransportInfo/ProfileTransportInfo'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import React from 'react'
import { GibddInfo } from './GibddInfo'
import { InsuranceData } from './InsuranceData'
import 'assets/Roboto-Bold-normal'
import 'assets/Roboto-Regular-normal'
import * as S from './Profile.styled'
import { Header } from './components/Header'
import { Table, Image } from 'antd'
import 'svg2pdf.js'
import tripS from '../TransportInfo/TransportInfo.module.scss'
import { useProfileReport } from './useProfilreReport'
import { PublicTransportInfo } from './ProfileTransportInfo/PublicTransportInfo'
import checkedIcon from 'assets/checkedIcon.svg'
import { useTransportActivityData } from 'components/Map/useTransportActivityData'

const transformDate = (str: string) => {
  const [date, time] = str.split('T')
  const [YYYY, MM, DD] = date.split('-')
  const [hour, min] = time.split(':')

  return `${DD}.${MM}.${YYYY} - ${hour}:${min}`
}

const columns: any = [
  {
    title: 'ГРЗ',
    dataIndex: 'grz',
    align: 'center',
    width: '5%',
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    align: 'left',
    width: '15%',
  },
  {
    title: 'Фото',
    dataIndex: 'photo',
    align: 'center',
    width: '10%',
  },
]

export const Profile = observer(({ type }: { type?: string }) => {
  const { rootStore } = useContext(Context)
  const { profileStore, mapStore } = rootStore

  const {
    fetchProfileData,
    isProfileDataRequested,
    metaData,
    isAuth,
    authUser,
    mapId,
    isSaving,
    tripPhotosId,
    tripPhotosRequested,
    fetchProfileError,
    additionalImagesAdded,
    radarId,
    tripPhotosAdded,
    tripPhotosTableData,
    setIsSaving,
    setIsFromProfile,
    userPhotos,
  } = profileStore

  const { isDataRequested: mapDataRequested, profileMapError } = mapStore

  const tableRef = useRef<any>(null)

  const params = useParams()?.data

  useEffect(() => {
    if (!isAuth) {
      authUser()
    }
  }, [authUser, isAuth])

  useEffect(() => {
    if (isAuth) {
      if (!isProfileDataRequested) {
        fetchProfileData(params?.split('&'))
      } else {
        setIsFromProfile(false)
      }
    }
  }, [
    fetchProfileData,
    isAuth,
    setIsFromProfile,
    isProfileDataRequested,
    params,
  ])

  const smallerThan768 = useMediaPredicate('(max-width: 769px)')
  const biggerThan768 = useMediaPredicate('(min-width: 769px)')
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    if (smallerThan768) {
      setIsMobile(true)
    }

    if (biggerThan768) {
      setIsMobile(false)
    }
  }, [smallerThan768, isMobile, biggerThan768])

  const hasTripPhotos = useMemo(
    () => tripPhotosId || tripPhotosAdded || additionalImagesAdded,
    [additionalImagesAdded, tripPhotosAdded, tripPhotosId]
  )

  const profileTransportLongData = useMemo(() => {
    const result = metaData
      ? Object.fromEntries(
          Object.entries(metaData).filter(([key, values]) => {
            return (
              (key === 'grzs' && Array.isArray(values) && values.length) ||
              (key === 'marks' && Array.isArray(values) && values.length)
            )
          })
        )
      : null

    return result
  }, [metaData])

  const getColDescr = (obj: any) => (
    <>
      {obj.grz && (
        <span className={tripS.field}>
          <b>ГРЗ: </b>
          <span>{obj.grz}</span>
          <br />
        </span>
      )}
      {obj.check_dttm && (
        <span className={tripS.field}>
          <b>Дата: </b>
          <span>{transformDate(obj.check_dttm)}</span>
          <br />
        </span>
      )}
      {obj.camera_address && (
        <span className={tripS.field}>
          <b>Адрес: </b>
          <span>{obj.camera_address}</span>
          <br />
        </span>
      )}
      {obj.check_id && (
        <span className={tripS.field}>
          <b>Камера: </b>
          <span>{obj.check_id}</span>
        </span>
      )}
    </>
  )

  const tableDataSource = tripPhotosTableData?.data?.map(
    (obj: any, idx: number) => {
      const grzPhoto = obj?.photo?.find(
        (photo: any) => photo.type === -1
        // )?.base64Value
      )?.photo
      const autoPhoto = obj?.photo?.find(
        (photo: any) => photo.type === 0
      )?.photo
      // )?.base64Value

      return {
        grz: (
          <div className={tripS.grzWrapper}>
            {grzPhoto ? (
              <Image
                className={tripS.image}
                preview={{ mask: false }}
                height={50}
                width={150}
                src={grzPhoto}
                // src={`data:image/jpeg;base64,${grzPhoto}`}
              />
            ) : (
              <b>{obj?.grz}</b>
            )}
          </div>
        ),
        dataIndex: obj.dataIndex,
        description: getColDescr(obj),
        key: `title_${idx}`,
        photo: (
          <Image
            className={tripS.image}
            preview={{ mask: false }}
            height={100}
            width={100}
            // src={`data:image/jpeg;base64,${autoPhoto ?? grzPhoto ?? obj?.grz}`}
            src={autoPhoto ?? grzPhoto ?? obj?.grz}
          />
        ),
      }
    }
  )

  const { handleDownloadPdf } = useProfileReport({
    tableRef,
    transportInfo: profileTransportLongData,
    type,
  })

  const { hasTransData } = useTransportActivityData()

  const logoSx: SxProps = useMemo(() => {
    if (userPhotos?.length) {
      return {
        top: {
          xs: 40,
          sm: 20,
          md: -20,
        },
        img: {
          width: 100,
          height: 100,
        },
      }
    }

    return {
      top: {
        xs: 20,
        sm: -25,
      },

      img: {
        width: {
          xs: 75,
          sm: 85,
          md: 100,
        },
        height: {
          xs: 75,
          sm: 85,
          md: 100,
        },
      },
    }
  }, [userPhotos?.length])

  if (fetchProfileError) {
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        {fetchProfileError}
      </Stack>
    )
  }

  return (
    <Stack
      id='profilePage'
      sx={{ height: '100vh', width: '100vw', overflowY: 'scroll' }}
    >
      {((tripPhotosId && !tripPhotosRequested) ||
        (mapId && !mapDataRequested) ||
        isSaving) && <Loading />}

      <Stack
        alignItems='center'
        justifyContent='center'
        position='relative'
        sx={{
          width: '100%',
          maxWidth: '900px',
          margin: '0 auto',
          pb: '40px',
          mt: 3,
        }}
      >
        <Stack
          sx={{
            width: 1,
            alignItems: 'flex-end',
            zIndex: 10,
            position: 'absolute',
            left: 0,
            right: 0,
            ...logoSx,
          }}
        >
          <img
            src={checkedIcon}
            alt='checkedIcon'
            width={'100%'}
            height={'100%'}
          />
        </Stack>

        <PersonalInfo />

        {hasTripPhotos ? <TransportGallery {...{ isMobile }} /> : null}

        <ProfileTransportInfo info={profileTransportLongData} />

        <GibddInfo />

        <InsuranceData />

        <Box sx={{ px: 2.5, maxWidth: '900px', width: '100%', mt: 2 }}>
          <PublicTransportInfo />
        </Box>

        {(mapId || radarId) && !profileMapError ? (
          <Box sx={{ px: 2, maxWidth: '900px', width: '100%', mt: 2 }}>
            {hasTransData ? (
              <Header
                text='Активность'
                icon={
                  <GpsFixedIcon
                    sx={{
                      bgcolor: '#E7CA32',
                      color: 'common.white',
                      p: '3px',
                      borderRadius: 7,
                    }}
                  />
                }
              />
            ) : null}

            <Map mapId={mapId} />
            {/* <Box mt={5}>
                <Typography variant='h4'>
                  НОВАЯ КАРТА
                </Typography>

                <NewMap mapId={mapId} />
              </Box> */}
          </Box>
        ) : null}

        <Box sx={{ px: 2, maxWidth: '900px', width: '100%', mt: 2 }}>
          <S.DownloadPdfBtn
            variant='contained'
            onClick={() => {
              setIsSaving(true)
              handleDownloadPdf()
            }}
          >
            Сохранить в PDF
          </S.DownloadPdfBtn>
        </Box>

        <Stack display='none'>
          <Table
            ref={tableRef}
            style={{ display: 'none' }}
            columns={columns}
            dataSource={tableDataSource}
            size='small'
            pagination={{ pageSize: 40 }}
          />
          <Table
            className={tripS.transportTable}
            columns={columns}
            dataSource={tableDataSource}
            size='small'
            pagination={{ pageSize: 5, position: ['bottomCenter'] }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
})
